import { SelectIdGroupContext } from '_context/SelectGroupContext'
import { setGroupIdSelected } from '_utils/localData'
import React, { useContext, useEffect, useState } from 'react'

const EventSelectGroup = (props) => {
  const { register, options, disabled, selected, error, setValue } = props
  const { infoGroupSelected, setInfoGroupSelected } = useContext(SelectIdGroupContext)
  const groupId = infoGroupSelected?.groupId
  const [gid, setGid] = useState('')

  useEffect(() => {
    if (groupId !== undefined) {
      setGid(groupId)
    }
  }, [groupId])

  useEffect(() => {
    if (gid) {
      setValue('groupId', gid)
      setInfoGroupSelected({
        ...infoGroupSelected,
        groupId: gid,
      })
    }
  }, [gid])

  const handleChangeGroup = ({ value }) => {
    setGid(value)
    setGroupIdSelected(value)
  }

  return (
    <div className='c-form mt-2'>
      <div className='form-group form-group--select'>
        <select
          className='form-control form-control--height'
          {...register}
          value={gid}
          onChange={(e) => handleChangeGroup(e.target)}
          disabled={disabled}
        >
          {options?.map((group, index) => (
            <option value={group.id} key={index} defaultValue={selected === group.id}>
              {group.name}
            </option>
          ))}
        </select>

        <small className='errorMessage'>{error}</small>
      </div>
    </div>
  )
}

export default EventSelectGroup

import invoiceApi from '_api/invoice'
import { removeInvoice } from '_redux/modules/invoice'
import {
  COLOR_STATUS_APPROVED,
  COLOR_STATUS_CANCELLED,
  COLOR_STATUS_FAILED,
  COLOR_STATUS_PENDING,
  COLOR_STATUS_REFUNDED,
  CO_BEE_FEATURE_APPROVED_STATUS,
  CO_BEE_FEATURE_CANCEL_STATUS,
  CO_BEE_FEATURE_FAILED_STATUS,
  CO_BEE_FEATURE_PENDING_STATUS,
  CO_BEE_FEATURE_REFUNDED_STATUS,
  PACKAGE_TYPE_COBEE,
  PACKAGE_TYPE_PREMIUM_ACCOUNT,
} from '_utils/constant'
import { formatNumberToCurrencyHasDot } from '_utils/function'
import { getUserInfo } from '_utils/localData'
import moment from 'moment/moment'
import React, { useContext } from 'react'
import { FaRegEye, FaTrash } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { UserContext } from '..'

function InvoiceItem({ item, onShowDetail }) {
  const dispatch = useDispatch()
  const userInfo = getUserInfo()

  const { onCancelPlan } = useContext(UserContext)

  const mapStatus = (status) => {
    const STATUS_MAP = {
      [CO_BEE_FEATURE_APPROVED_STATUS]: { statusText: 'Approved', color: COLOR_STATUS_APPROVED },
      [CO_BEE_FEATURE_PENDING_STATUS]: { statusText: 'Pending', color: COLOR_STATUS_PENDING },
      [CO_BEE_FEATURE_CANCEL_STATUS]: { statusText: 'Cancelled', color: COLOR_STATUS_CANCELLED },
      [CO_BEE_FEATURE_FAILED_STATUS]: { statusText: 'Failed', color: COLOR_STATUS_FAILED },
      [CO_BEE_FEATURE_REFUNDED_STATUS]: { statusText: 'Refunded', color: COLOR_STATUS_REFUNDED },
    }

    const { statusText = '', color = '' } = STATUS_MAP[status] || {}
    return [statusText, color]
  }

  // eslint-disable-next-line no-unused-vars
  // const mapPaymentStatus = (paymentStatus) => {
  //   const PAYMENT_STATUS_MAP = {
  //     [CO_BEE_FEATURE_VERIFY_PAYMENT_STATUS]: { statusText: 'Verified', color: '#15cdca' },
  //     [CO_BEE_FEATURE_PAID_PAYMENT_STATUS]: { statusText: 'Paid', color: '#15cdca' },
  //     [CO_BEE_FEATURE_UNPAID_PAYMENT_STATUS]: { statusText: 'Unpaid', color: '#F06A0F' },
  //     [CO_BEE_FEATURE_EXPIRE_PAYMENT_STATUS]: { statusText: 'Expired', color: '#7f8c8d' },
  //     [CO_BEE_FEATURE_FAIL_PAYMENT_STATUS]: { statusText: 'Fail', color: '#7f8c8d' },
  //     [CO_BEE_FEATURE_REFUNDED_PAYMENT_STATUS]: { statusText: 'Refunded', color: '#2c3e50' },
  //   }

  //   const { statusText = '', color = '' } = PAYMENT_STATUS_MAP[paymentStatus] || {}
  //   return [statusText, color]
  // }

  let retryCount = 0
  const maxRetryCount = 2

  const onRemoveInvoice = async (invoice) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure remove?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#15cdca',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove it!',
      })

      if (result.isConfirmed) {
        switch (invoice.packageType) {
          case PACKAGE_TYPE_PREMIUM_ACCOUNT: {
            await dispatch(removeInvoice(invoice))
            localStorage.removeItem('newestTransaction')
            break
          }
          default:
            break
        }
        retryCount = 0
        Swal.fire('Removed!', 'Your file has been removed.', 'success')
      }
    } catch (error) {
      retryCount += 1
      if (retryCount <= maxRetryCount) {
        Swal.fire({
          title: 'Error!',
          text: `There was an issue removing your invoice. Please try again. (${retryCount}/${maxRetryCount} attempts)`,
          icon: 'error',
          confirmButtonText: 'Try Again',
        }).then((result) => {
          if (result.isConfirmed) {
            onRemoveInvoice(invoice)
          }
        })
      } else {
        let timerInterval
        Swal.fire({
          title: 'Error!',
          html: 'There was an issue removing your invoice. Please wait <b>5</b> seconds.',
          icon: 'error',
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Math.round(Swal.getTimerLeft() / 1000)
            }, 1000)
          },
          willClose: () => {
            clearInterval(timerInterval)
          },
          showConfirmButton: false,
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            retryCount = 0
            if (retryCount === maxRetryCount) {
              onRemoveInvoice(invoice)
            }
          }
        })
      }
    }
  }

  const onConfirmInvoice = async (invoice) => {
    try {
      const { packageType, id, groupId } = invoice
      const params = {
        packageType,
        invoiceId: id,
      }
      if (packageType === PACKAGE_TYPE_COBEE) {
        params.groupId = groupId
      }
      const { msgResp: paymentUrl } = await invoiceApi.confirmInvoice(params)

      window.open(paymentUrl, '_blank')
    } catch (error) {
      Swal.fire({
        title: 'Confirm remove transaction',
        text: 'You paid this feature, do you want remove this transaction?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#15cdca',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(removeInvoice(invoice))
          Swal.fire('Removed!', 'Your file has been removed.', 'success')
        }
      })
    }
  }

  return (
    <div className='invoice-item row mx-0 border mb-3 rounded-3 shadow-sm'>
      <div className='col-12'>
        <div className='p-2 d-flex align-items-center justify-content-between'>
          <p className='d-flex align-items-center'>
            <b className='text-525252'>ID: {item.id.slice(0, 6)}</b>
            <button
              type='button'
              onClick={() => onShowDetail(item)}
              className='bg-transparent border-0'
            >
              <FaRegEye
                className='ms-1 cursor-pointer'
                size={16}
                style={{
                  transform: 'translateY(-1px)',
                }}
              />
            </button>
          </p>
          {item.status === CO_BEE_FEATURE_PENDING_STATUS && (
            <button
              type='button'
              onClick={() => onRemoveInvoice(item)}
              style={{
                transform: 'translateY(-2px)',
              }}
              className='bg-transparent border-0 p-0 fw-semibold'
            >
              <FaTrash color='#c0392b' />
            </button>
          )}
        </div>
      </div>
      <div className='w-100 border-bottom'></div>
      <div className='col-6'>
        <div className='d-flex flex-column align-items-start justify-content-between h-100 ps-2 pt-2 pb-2'>
          <p>
            Service Type: <b className='text-525252'>{item.packageName}</b>
            {!item.price === 0 ? (
              <span className='ms-1 fw-semibold text-525252'>(Trial)</span>
            ) : null}
          </p>
          {item.packageType === PACKAGE_TYPE_COBEE && (
            <p>
              Group Name:{' '}
              <b>{userInfo?.groups?.find((group) => group.gid === item.groupId)?.name}</b>
            </p>
          )}
          <p>
            Price:{' '}
            <b className='text-price' style={{ color: '#15cdca' }}>
              {!item.price === 0 ? '$0' : `$${formatNumberToCurrencyHasDot(`${item.price}`)}`}
            </b>
          </p>
          <p>
            Status: <b style={{ color: mapStatus(item.status)[1] }}>{mapStatus(item.status)[0]}</b>
          </p>
        </div>
      </div>
      <div className='col-6'>
        <div className='d-flex flex-column align-items-end justify-content-between h-100 pt-2 pe-2 pb-2'>
          <p>
            Created:{' '}
            <span className='text-525252 fw-semibold'>
              {moment.unix(item.createdAt).format('DD-MM-YYYY')}
            </span>
          </p>
          {item.activeDateTimestamp ? (
            <p className='mb-auto'>
              Activated:
              <span className='text-525252 fw-semibold'>
                {moment.unix(item.activeDateTimestamp).format('DD-MM-YYYY')}
              </span>
            </p>
          ) : null}
          {item.status === CO_BEE_FEATURE_REFUNDED_STATUS && item.lastModifiedAt ? (
            <p className='mb-auto'>
              Refunded:
              <span className='text-525252 fw-semibold'>
                {moment.unix(item.lastModifiedAt).format('DD-MM-YYYY')}
              </span>
            </p>
          ) : null}
          {item.refund ? (
            <p className='mb-auto'>
              System Refunded:{' '}
              <b style={{ color: '#c0392b' }}>${formatNumberToCurrencyHasDot(`${item.refund}`)}</b>
            </p>
          ) : null}

          {!item.price === 0 && item.status !== CO_BEE_FEATURE_CANCEL_STATUS ? (
            <button
              type='button'
              onClick={() => onCancelPlan(item)}
              // style={{ fontSize: 12 }}
              className='bg-transparent btn-cancel-plan px-2 py-0 border fw-semibold w-auto rounded-pill shadow-none mt-auto d-inline-block text-secondary'
            >
              Cancel plan
            </button>
          ) : null}

          {item.status === CO_BEE_FEATURE_PENDING_STATUS && (
            <button
              type='button'
              onClick={() => onConfirmInvoice(item)}
              // style={{ fontSize: 11 }}
              className='btn btn-primary fw-semibold w-auto rounded-pill shadow-none mt-auto d-inline-block px-2 py-0'
            >
              Confirm
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default InvoiceItem

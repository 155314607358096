import LongText from '_components/LongText'
import MySlider from '_components/MySlider'
import moment from 'moment'
import React from 'react'
import LinkIcons from './LinkIcons'

function ShopItem({ shop }) {
  return (
    <div className='shop-item bg-white shadow-sm overflow-hidden mb-3'>
      <a
        href={`https://stg.co-kii.com/${shop.shopSlug}`}
        rel='noreferrer'
        target={'_blank'}
        className='me-0'
      >
        <div
          className='wrap-banner'
          style={{
            height: 250,
          }}
        >
          {/* {shop?.logo.length === 1 ? (
            <img src={} alt='banner' />
          ) : (
            <div
              className={'d-flex align-items-center justify-content-center h-100 fw-semibold'}
              style={{
                backgroundColor: '#f5f5f5',
              }}
            >
              No Image
            </div>
          )} */}
          <MySlider listImage={shop?.logo} className={'img-fluid'} />
        </div>
      </a>

      <div className='shop-content p-3'>
        <div className='shop-name d-flex align-items-center mb-2'>
          <a href={`https://stg.co-kii.com/${shop.shopSlug}`} rel='noreferrer' target={'_blank'}>
            {shop.shopName}
          </a>
          {moment().diff(moment.unix(shop.createdAt), 'hours') < 24 ? (
            <span className='badge bg-danger'>New</span>
          ) : null}
        </div>
        <div className='shop-description'>
          <LongText content={shop.headline} limit={100} />
        </div>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='link-icons'>
            <LinkIcons shop={shop} />
          </div>
          <div className='author d-flex align-items-start flex-column'>
            <small>Created by: {shop.createdUserName}</small>
            <small>Date: {moment(shop.createdAt * 1000).format('DD/MM/YYYY')}</small>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShopItem

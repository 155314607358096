/* eslint-disable no-unused-vars */
import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Bar } from 'react-chartjs-2'

function Top10EventsChart({ data }) {
  const { addToast } = useToasts()
  const { summaryData, errorGetSummary } = useSelector((state) => state.dashBoard)

  const topEvent = useMemo(() => {
    const listLabel = summaryData?.topEvent.map((item) => item.eTitle)
    const listValue = summaryData?.topEvent.map((item) => item.totalValue)
    return {
      listLabel,
      listValue,
    }
  }, [summaryData])

  if (errorGetSummary) {
    return addToast(errorGetSummary, { appearance: 'error', autoDismiss: true })
  }

  return (
    <div>
      <Bar
        options={{
          indexAxis: 'y',
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          responsive: true,
          plugins: {
            legend: {
              position: 'right',
              display: false,
            },
          },
        }}
        data={{
          labels: topEvent?.listLabel,
          datasets: [
            {
              label: 'TOTAL VALUE $',
              data: topEvent?.listValue,
              borderColor: ['#34CCCD'],
              backgroundColor: ['#34CCCD'],
            },
          ],
        }}
      />
    </div>
  )
}

export default memo(Top10EventsChart)

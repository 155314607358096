import { convertToCurrentGMT, isDisableEvent, shortDescription } from '_utils/function'
import moment from 'moment'
import React, { createRef, useEffect, useState } from 'react'
import { FaTruck } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'

import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_COLLECTED,
  BUYER_ORDER_PAID,
  BUYER_ORDER_UNPAID,
  DELIVERY_AND_HAVE_BOOKED,
  DELIVERY_BUT_HAVE_NOT_BOOKED,
  DELIVERY_NOT_CLOSE,
  HOST_ORDER_CANCELED,
  HOST_ORDER_COLLECTED,
  HOST_ORDER_UNPAID,
  HOST_ORDER_VERIFIED,
  LIMIT_TEXT,
  LIST_TIME,
  NO_DELIVERY,
  PAYABLE,
  PAYNOW,
} from '_utils/constant'
import HeaderEventInfo from '../components/HeaderEventInfo'
import ButtonCollectionToday from './ButtonCollectionToday'
import ButtonCollectionTomorrow from './ButtonCollectionTomorrow'
import ButtonDayCollection from './ButtonDayCollection'
import ButtonPayment from './ButtonPayment'
import ButtonPendingConfirmation from './ButtonPendingConfirmation'

export default function ItemOrder({ order = {}, setOrderSelecting }) {
  const history = useHistory()
  const [isShowMore, setIsShowMore] = useState(false)
  const [heightElement, setHeightElement] = useState(0)
  const refElement = createRef()

  useEffect(() => {
    setHeightElement(refElement.current.clientHeight)
  })

  const showMore = (e) => {
    e.stopPropagation()
    setIsShowMore(true)
  }
  const isDisable = isDisableEvent(
    order?.status,
    order?.buyerStatus,
    convertToCurrentGMT(order?.ePickupTime),
    order?.ePickupDuration
  )

  const onClickItemOrder = () => {
    history.push(`/event/purchase-detail?ref=${order?.ref}`)
  }
  const numDay = (date) => {
    const given = moment(date.split(' ')[0], 'DD-MM-YYYY')
    const current = moment().startOf('day')
    return moment.duration(given.diff(current)).asDays()
  }
  const { buyerStatus, status, ePickupTime, ePickupDuration, ePayable, ePaynow } = order || {}
  const [showButtonPayment, setShowButtonPayment] = useState(false)
  const [showButtonDayCollection, setShowButtonDayCollection] = useState(false)
  const [showButtonCollectionTomorrow, setShowButtonCollectionTomorrow] = useState(false)
  const [showButtonCollectionToday, setShowButtonCollectionToday] = useState(false)
  const [showButtonPending, setShowButtonPending] = useState(false)
  const [collectionTimeStart, setCollectionTimeStart] = useState('')
  const [collectionTimeEnd, setCollectionTimeEnd] = useState('')

  useEffect(() => {
    setShowButtonPayment(false)
    setShowButtonDayCollection(false)
    setShowButtonCollectionTomorrow(false)
    setShowButtonCollectionToday(false)
    setShowButtonPending(false)
    if (ePickupTime && ePickupDuration) {
      const pickupTimeCurrentGMT = convertToCurrentGMT(ePickupTime)
      const timeStart = Number(pickupTimeCurrentGMT.split(' ')[1].split(':')[0])
      const timeEnd = timeStart + ePickupDuration / 3600
      if (timeEnd <= 24) {
        const indexStart = LIST_TIME.findIndex((item) => item.id === timeStart)
        const indexEnd = LIST_TIME.findIndex((item) => item.id === timeEnd % 24)
        setCollectionTimeStart(LIST_TIME[indexStart]?.text)
        setCollectionTimeEnd(LIST_TIME[indexEnd]?.text)
      } else {
        setCollectionTimeStart('')
        setCollectionTimeEnd('')
      }
    }
    if (
      buyerStatus !== BUYER_ORDER_CANCELED &&
      status !== HOST_ORDER_CANCELED &&
      buyerStatus !== BUYER_ORDER_COLLECTED &&
      status !== HOST_ORDER_COLLECTED
    ) {
      if (buyerStatus === BUYER_ORDER_UNPAID && status === HOST_ORDER_UNPAID) {
        if (ePaynow === PAYNOW || (ePaynow !== PAYNOW && ePayable === PAYABLE)) {
          setShowButtonPayment(true)
        }
      } else if (buyerStatus === BUYER_ORDER_PAID && status === HOST_ORDER_UNPAID) {
        setShowButtonPending(true)
      } else if (buyerStatus === BUYER_ORDER_PAID && status === HOST_ORDER_VERIFIED) {
        if (numDay(convertToCurrentGMT(ePickupTime)) > 1) {
          setShowButtonDayCollection(true)
        } else if (numDay(convertToCurrentGMT(ePickupTime)) === 1) {
          setShowButtonCollectionTomorrow(true)
        } else {
          setShowButtonCollectionToday(true)
        }
      }
    }
  }, [buyerStatus, status, ePickupTime, ePickupDuration, ePayable, ePaynow])

  return (
    <div
      key={order?.id}
      style={{ cursor: 'pointer' }}
      className={`group-list__item ${isDisable ? 'is-closed' : ''}`}
    >
      <HeaderEventInfo
        createdUserPhotoUrl={order?.eCreatedUserPhotoUrl}
        createdUserName={order?.eCreatedUserName}
        eClosingTime={convertToCurrentGMT(order?.eClosingTime)}
        eTotalOrderCount={order?.eTotalOrderCount}
        groupName={order?.eGroupName}
        collectionTimeStart={collectionTimeStart}
        collectionTimeEnd={collectionTimeEnd}
        ePickupTime={convertToCurrentGMT(order?.ePickupTime)}
        isDisable={isDisable}
      />
      <div className='group-list__des'>
        <div className='d-flex justify-content-between'>
          <h4 onClick={onClickItemOrder}>{order?.eTitle}</h4>
          {order?.deliveryApplied !== NO_DELIVERY && !isDisable && (
            <a
              style={{
                color: '#079d9a',
                fontSize: '0.875rem',
              }}
              onClick={(e) => {
                e.stopPropagation()
                setOrderSelecting(order)
              }}
            >
              {order?.deliveryApplied === DELIVERY_AND_HAVE_BOOKED && (
                <>
                  Delivery Included <FaTruck color='#079d9a' />
                </>
              )}
              {order?.deliveryApplied === DELIVERY_BUT_HAVE_NOT_BOOKED &&
                order?.eDeliveryStatus === DELIVERY_NOT_CLOSE && (
                  <>
                    Add Delivery <FaTruck color='#079d9a' />
                  </>
                )}
            </a>
          )}
        </div>
        <div
          className={`${
            heightElement < 30 ? 'flex-row justify-content-between align-items-end' : 'flex-column'
          } d-flex mb-3 overflow-hidden`}
        >
          <div
            className='group-list__des-text paragraph-pre-wrap w-75 mb-0'
            ref={refElement}
            style={isShowMore ? { height: 'auto' } : { height: '25px' }}
            onClick={onClickItemOrder}
          >
            {isShowMore
              ? order?.eDescription
              : shortDescription({ description: order?.eDescription })}
            {!isShowMore &&
            order?.eDescription &&
            order?.eDescription.split(' ').length > LIMIT_TEXT ? (
              <span
                style={{ textDecoration: 'underline', cursor: 'pointer', display: 'inline' }}
                onClick={showMore}
              >
                More
              </span>
            ) : null}
          </div>
          {heightElement >= 30 ? (
            <span style={{ fontSize: 16, color: '#15cdca' }} onClick={() => setIsShowMore(false)}>
              Show less
            </span>
          ) : (
            <span style={{ fontSize: 16, color: '#15cdca' }} onClick={() => setIsShowMore(true)}>
              Show more
            </span>
          )}
        </div>
        {showButtonPayment ? (
          <div onClick={onClickItemOrder}>
            <ButtonPayment isDisable={isDisable} />
          </div>
        ) : null}
        {showButtonPending ? (
          <div onClick={onClickItemOrder}>
            <ButtonPendingConfirmation isDisable={isDisable} />
          </div>
        ) : null}
        {showButtonDayCollection ? (
          <div onClick={onClickItemOrder}>
            <ButtonDayCollection
              day={numDay(convertToCurrentGMT(ePickupTime))}
              isDisable={isDisable}
            />
          </div>
        ) : null}
        {showButtonCollectionTomorrow ? (
          <div onClick={onClickItemOrder}>
            <ButtonCollectionTomorrow
              collectionTimeStart={collectionTimeStart}
              collectionTimeEnd={collectionTimeEnd}
            />
          </div>
        ) : null}
        {showButtonCollectionToday ? (
          <div onClick={onClickItemOrder}>
            <ButtonCollectionToday
              isDisable={isDisable}
              collectionTimeStart={collectionTimeStart}
              collectionTimeEnd={collectionTimeEnd}
            />
          </div>
        ) : null}
      </div>
      {order?.status === HOST_ORDER_CANCELED ? (
        <span
          style={{
            width: '100%',
            textAlign: 'center',
            marginTop: '10px',
            border: '1px solid',
            borderRadius: '10px',
          }}
        >
          CANCELLED
        </span>
      ) : null}
    </div>
  )
}

import React, { useState } from 'react'
import { DateRange } from 'react-date-range'
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'

function SelectDate({ onChangeRangeDate }) {
  const [rangeDate, setRangeDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const [modal, setModal] = useState(false)

  const handleSelectDate = (ranges) => {
    const { startDate, endDate } = ranges.selection
    setRangeDate([{ startDate, endDate, key: 'selection' }])
  }

  const handleSubmitDate = () => {
    onChangeRangeDate(rangeDate)
    setModal(false)
  }

  const handleCancel = () => {
    setModal(false)
  }

  return (
    <>
      <Button
        onClick={() => setModal(!modal)}
        role='button'
        className='btn btn-date rounded-0 ms-3 shadow-none'
        outline
      >
        Date
      </Button>
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalBody>
          <DateRange
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            onChange={handleSelectDate}
            ranges={rangeDate}
            className='w-100'
            rangeColors={['#15cdca']}
          />
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col>
              <Button
                color='primary'
                role='button'
                onClick={(item) => handleSubmitDate([item.selection])}
              >
                Ok
              </Button>
            </Col>
            <Col>
              <button
                className='bg-transparent text-black btn-cancel-calendar'
                type='button'
                onClick={handleCancel}
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default SelectDate

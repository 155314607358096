import CollapseProvider from '_context/CollapseMenuContext'
import { DeviceUserContext } from '_context/DeviceUserContext'
import SelectGroupContext from '_context/SelectGroupContext'
import { getMyGroups } from '_redux/modules/group'
import { setDeviceUser } from '_utils/localData'
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DESKTOP } from '../utils/constant'
import MainDesktop from './Main'
import Header from './components/Header'

function AppDesktop() {
  const dispatch = useDispatch()
  const { onSwitchDevice } = useContext(DeviceUserContext)
  const { user } = useSelector((state) => state.user)

  useEffect(() => {
    dispatch(getMyGroups)
  }, [])

  useEffect(() => {
    if (user?.features?.premiumAccount) {
      setDeviceUser(DESKTOP)
      onSwitchDevice(DESKTOP)
    }
  }, [JSON.stringify(user?.features)])

  return (
    <React.Fragment>
      <SelectGroupContext>
        <CollapseProvider>
          <Header />
          <section
            className='section-content section-content--top mains-screen h-100 pb-0'
            id='checkHeight'
          >
            <MainDesktop />
          </section>
        </CollapseProvider>
      </SelectGroupContext>
    </React.Fragment>
  )
}

export default AppDesktop

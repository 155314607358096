import useLogout from '_hooks/useLogout'
import { userLogout } from '_redux/modules/user'
import React from 'react'
import { useDispatch } from 'react-redux'
import FeedBack from './components/FeedBack'
import NotificationList from './components/NotificationList'

const Notifications = () => {
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(userLogout())
    useLogout()
  }
  return (
    <div className='profile-page'>
      <NotificationList />
      <div className='row row--bot align-self-end'>
        <FeedBack />
        <div className='col-12 logout-btn' onClick={onLogout}>
          <ul className='action-link'>
            <li>Logout</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Notifications

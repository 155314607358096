import { getCreateEventData, getUserInfo, removeCreateEventData } from '_utils/localData'
import React, { useEffect, useState } from 'react'
// import { HiOutlineShoppingBag } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { Link, useHistory, withRouter } from 'react-router-dom'

import { convertToCurrentGMT, isDisableEvent } from '_utils/function'
import './style.scss'

const Footer = ({ location }) => {
  const user = getUserInfo()
  const history = useHistory()
  const [pathname, setPathname] = useState(location.pathname.split('/'))
  const { myEvents } = useSelector((state) => state.event)
  const [totalMyPurchases, setTotalMyPurchases] = useState(0)
  const { myOrders, orderDetail } = useSelector((state) => state.order)
  const [isAllowHost, setIsAllowHost] = useState(false)

  useEffect(() => {
    const listOrderUnClosed = myOrders.filter((item) => {
      const isDisable = isDisableEvent(
        item?.status,
        item?.buyerStatus,
        convertToCurrentGMT(item?.ePickupTime),
        item?.ePickupDuration
      )
      if (!isDisable) {
        return item
      }
      return null
    })
    setTotalMyPurchases(listOrderUnClosed.length)
  }, [myOrders?.length, orderDetail?.length])

  useEffect(() => {
    if (user) {
      const isHost = user?.groups?.some((_user) => _user?.isHost || _user?.isAdmin)

      if (isHost) {
        setIsAllowHost(true)
      }
    }
  }, [JSON.stringify(user)])

  const checkHighLight = () => {
    const paths = [
      'create',
      'create-items',
      'created-confirm',
      'update',
      'update-items',
      'update-confirm',
    ]

    return pathname[1] === 'event' && paths.includes(pathname[2].split('?')[0])
  }

  const handleOnClickToCreateEvent = () => {
    const eventData = getCreateEventData()
    if (eventData?.id) {
      removeCreateEventData()
    }
    history.push('/event/create')
  }

  useEffect(() => {
    setPathname(location.pathname.split('/'))
  }, [location])

  return (
    <React.Fragment>
      {(isAllowHost || totalMyPurchases > 0) && (
        <footer className='footer footer-admin'>
          <div className='container'>
            <ul
              className={`menu ${
                !isAllowHost && !myEvents?.length && totalMyPurchases
                  ? 'justify-content-center'
                  : ''
              }`}
            >
              {isAllowHost && (
                <li>
                  <a
                    className={`${checkHighLight() ? 'active' : ''} cursor-pointer`}
                    onClick={handleOnClickToCreateEvent}
                  >
                    <i className='icon-host'></i>
                    <span>Host</span>
                  </a>
                </li>
              )}
              {/* {!!totalMyPurchases && (
                <li>
                  <Link
                    to='/event/list-my-purchases'
                    className={`step-4 ${
                      pathname[1] === 'event' && pathname[2] === 'list-my-purchases' ? 'active' : ''
                    }`}
                  >
                    <div className='position-relative'>
                      <HiOutlineShoppingBag size={30} />
                      <span
                        id='totalMyPurchases'
                        style={{
                          position: 'absolute',
                          top: 9,
                          left: 13,
                        }}
                      >
                        {totalMyPurchases}
                      </span>
                    </div>
                    My Purchase
                  </Link>
                </li>
              )} */}
              {isAllowHost && (
                <li>
                  <Link
                    to='/event/list-my-events'
                    className={
                      pathname[1] === 'event' && pathname[2] === 'list-my-events' ? 'active' : ''
                    }
                  >
                    <i className='icon-host-event'></i>
                    <span>
                      Hosted Event {!!myEvents?.length && <span>({myEvents?.length})</span>}
                    </span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </footer>
      )}
    </React.Fragment>
  )
}

export default withRouter(Footer)

import axiosClient from './axiosClient'

const shopApi = {
  getListShopPaid: () => {
    const url = '/get-list-shop-paid'

    return axiosClient.get(url)
  },
}
export default shopApi

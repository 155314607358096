/* eslint-disable indent */
import orderApi from '_api/order'
import EmptyStateMessage from '_components/EmptyStateMessage'
import LoadingComponent from '_components/LoadingComponent'
import TableHeaderWithSort from '_components/TableHeaderWithSort'
import ModalDetailOrder from '_pages/Event/components/ModalDetailOrder'
import ModalMessage from '_pages/Event/components/ModalMessage'
import ModalPaymentScreen from '_pages/Event/components/ModalPaymentScreen'
import ModalWarning from '_pages/Event/components/ModalWarning'
import { updateDeliveryCombinedPayment } from '_redux/modules/delivery'
import {
  getListCombinedPayment,
  updateOrderCombinedPayment,
  updateOrderStatus,
} from '_redux/modules/event'
import {
  BUYER_CANCELED,
  BUYER_COLLECTED,
  BUYER_PAID,
  BUYER_REFUNDED,
  BUYER_UNPAID,
  HOST_ORDER_CANCELED,
  HOST_ORDER_COLLECTED,
  HOST_ORDER_UNPAID,
  HOST_ORDER_VERIFIED,
} from '_utils/constant'
import { handleDataOrder } from '_utils/function'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Col, FormGroup, Input, Row } from 'reactstrap'

function OrderList({ listOrder, setSorting, loadingGetEventAndOrder }) {
  // use hooks at the top
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const {
    eventAndOrderById,
    listCombinedPayments,
    listEventAndDelivery,
    loadingGetListCombinedPayment,
  } = useSelector((state) => state.event)

  // declare state
  const [modalMessage, setModalMessage] = useState(false)
  const [modalPaymentScreen, setModalPaymentScreen] = useState(false)
  const [modalDetailOrder, setModalDetailOrder] = useState(false)
  const [modalWarning, setModalWarning] = useState(false)
  const [dataModal, setDataModal] = useState({})
  const [dataModalDetailOrder, setDataModalDetailOrder] = useState({})
  const [isCanceling, setIsCanceling] = useState(false)

  // declare variable
  const headers = [
    {
      name: 'Name',
      field: 'uName',
      type: 'string',
      className: 'col col-auto',
      sortable: true,
    },
    {
      name: 'Mobile',
      field: 'uPhone',
      className: 'col col-auto',
      type: 'string',
      sortable: true,
    },
    {
      name: 'Event',
      field: 'eTitle',
      type: 'string',
      className: 'col col-auto',
      sortable: true,
    },
    {
      name: 'Date',
      field: 'createdAt',
      type: 'number',
      className: 'col col-auto',
      sortable: true,
    },
    {
      name: 'Purchase',
      field: '',
      type: 'string',
      className: 'col col-auto',
      sortable: true,
    },
    {
      name: 'Event Status',
      field: '',
      type: 'number',
      className: 'col col-auto text-center',
      sortable: true,
    },
    {
      name: 'Payment Status',
      field: 'buyerStatus',
      type: 'number',
      className: 'col col-auto text-center',
      sortable: true,
    },
    {
      name: 'Verified',
      field: 'status',
      type: 'number',
      className: 'col col-auto text-center',
      sortable: true,
    },
  ]

  const initListOrder = async (_data) => {
    const listCombinedPaymentRef = []
    _data?.forEach((element) => {
      const { combinedPaymentRef = '' } = element
      if (!listCombinedPaymentRef.includes(combinedPaymentRef) && combinedPaymentRef) {
        listCombinedPaymentRef.push(combinedPaymentRef)
      }
    })

    if (listCombinedPaymentRef.length) {
      await dispatch(getListCombinedPayment(listCombinedPaymentRef))
    } else {
      dispatch({
        type: 'GET_COMBINED_PAYMENT',
        payload: {
          loadingGetListCombinedPayment: false,
        },
      })
    }
  }

  useEffect(() => {
    if (listOrder?.length) {
      const fetchData = async () => {
        await initListOrder(listOrder)
      }

      fetchData()
    }
  }, [pathname, listOrder?.length])

  const toggleMessage = () => setModalMessage(!modalMessage)

  const togglePaymentScreen = () => setModalPaymentScreen(!modalPaymentScreen)

  const toggleDetailOrder = () => setModalDetailOrder(!modalDetailOrder)

  const toggleWarning = () => setModalWarning(!modalWarning)

  const onUpdateOrderStatus = async (ref, data, eid) => {
    try {
      dispatch(updateOrderStatus(eid, ref, data.status, data.buyerStatus))
      await orderApi.updateOrderStatusAndBuyerStatus(ref, data)
      addToast('Update status successfully', { appearance: 'success', autoDismiss: true })
    } catch (error) {}
  }

  const updateCombinedPayment = async (data) => {
    try {
      const { combinedPaymentRef, status, placerStatus } = data
      dispatch(updateOrderCombinedPayment(combinedPaymentRef, status, placerStatus))
      dispatch(updateDeliveryCombinedPayment(combinedPaymentRef, status, placerStatus))
      await orderApi.updateInvoicesStatus(data)
      addToast('Update status successfully', { appearance: 'success', autoDismiss: true })
    } catch (error) {}
  }

  const onChangeSelect = async (e, ref, status, buyerStatus, combinedPaymentRef, eid) => {
    e.preventDefault()
    const newStatus = Number(e.target.value)

    try {
      let data = { status: newStatus, buyerStatus }

      if (
        status === HOST_ORDER_UNPAID &&
        [HOST_ORDER_VERIFIED, HOST_ORDER_COLLECTED].includes(newStatus)
      ) {
        data.buyerStatus = BUYER_PAID
      } else if (
        status !== HOST_ORDER_CANCELED &&
        newStatus === HOST_ORDER_CANCELED &&
        buyerStatus > BUYER_UNPAID
      ) {
        data = combinedPaymentRef
          ? { combinedPaymentRef, status: HOST_ORDER_CANCELED, placerStatus: BUYER_REFUNDED }
          : { ...data, buyerStatus: BUYER_REFUNDED }
      } else if (
        status === HOST_ORDER_CANCELED &&
        newStatus !== HOST_ORDER_CANCELED &&
        buyerStatus === BUYER_REFUNDED
      ) {
        data.buyerStatus = BUYER_PAID
      }

      if (combinedPaymentRef && data.placerStatus) {
        await updateCombinedPayment(data)
      } else {
        await onUpdateOrderStatus(ref, data, eid)
      }
    } catch (error) {
      addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const openMessageModal = (e, order) => {
    e.preventDefault()
    setDataModal(order)
    toggleMessage()
  }

  const openPaymentScreen = (e, order) => {
    e.preventDefault()

    const { combinedPaymentRef = '' } = order
    let listCombinedOrder = []
    let listCombinedDelivery = []

    if (combinedPaymentRef) {
      const exitCombinedPayment = listCombinedPayments?.findIndex(
        (item) => item.combinedPaymentRef === combinedPaymentRef
      )

      if (exitCombinedPayment !== -1) {
        const { orderCombinedPayment, deliveryCombinedPayment } =
          listCombinedPayments[exitCombinedPayment]
        orderCombinedPayment
          // exclude current order
          .forEach((element) => {
            const { eid, ref: elementRef } = element
            const { orders, adminCost, discount, deliveryCost, productIdList } =
              eventAndOrderById.find((item) => item.id === eid) || {}
            const { listOrder: otherListOrder } = handleDataOrder(
              orders,
              adminCost,
              discount,
              deliveryCost,
              productIdList
            )

            const otherOrder = otherListOrder.find((item) => item.ref === elementRef)

            listCombinedOrder = [...listCombinedOrder, otherOrder].filter((el) => el)
          })
        listCombinedDelivery = [...deliveryCombinedPayment].filter((el) => el)
      }
    }

    setDataModal({ ...order, listCombinedOrder, listCombinedDelivery })
    togglePaymentScreen()
  }

  const handleInvalidPayment = async (ref, _status, _combinedPaymentRef, eid) => {
    try {
      const r = confirm('Are you sure this is invalid payment?')
      if (r === true) {
        if (_combinedPaymentRef) {
          const _data = {
            combinedPaymentRef: _combinedPaymentRef,
            status: HOST_ORDER_UNPAID,
            placerStatus: BUYER_UNPAID,
          }
          await updateCombinedPayment(_data)
        } else {
          await onUpdateOrderStatus(
            ref,
            {
              buyerStatus: BUYER_UNPAID,
              status: HOST_ORDER_UNPAID,
            },
            eid
          )
          // dispatch(updateOrderStatus(eid, ref, HOST_ORDER_UNPAID, BUYER_UNPAID))
        }
        togglePaymentScreen()
      }
    } catch (error) {
      addToast(error?.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    } finally {
      togglePaymentScreen()
    }
  }

  const handleShowDetail = (e, order) => {
    e.preventDefault()
    setDataModalDetailOrder(order)
    toggleDetailOrder()
  }

  const handleCancelOrder = async (e, ref, _status, _buyerStatus, _combinedPaymentRef, eid) => {
    e.preventDefault()
    try {
      setIsCanceling(true)
      if (_status !== HOST_ORDER_CANCELED && _buyerStatus > BUYER_UNPAID) {
        if (_combinedPaymentRef) {
          const _data = {
            combinedPaymentRef: _combinedPaymentRef,
            status: HOST_ORDER_CANCELED,
            placerStatus: BUYER_REFUNDED,
          }
          await updateCombinedPayment(_data)

          toggleDetailOrder()
          toggleWarning()
        } else {
          const _data = { status: HOST_ORDER_CANCELED, buyerStatus: BUYER_REFUNDED }
          await onUpdateOrderStatus(ref, _data, eid)

          toggleDetailOrder()
          toggleWarning()
        }
      } else {
        const _data = { status: HOST_ORDER_CANCELED, buyerStatus: BUYER_REFUNDED }
        await onUpdateOrderStatus(ref, _data, eid)

        toggleDetailOrder()
        toggleWarning()
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
      toggleDetailOrder()
      toggleWarning()
    } finally {
      setIsCanceling(false)
    }
  }

  const handleOpenWarning = (e, order) => {
    e.preventDefault()
    setDataModal(order)
    toggleWarning()
  }

  return (
    <div className='list-event'>
      <TableHeaderWithSort
        headers={headers}
        onSorting={(field, order, type) => setSorting({ field, order, type })}
        defaultSortingField='eOrderNo'
        defaultSortingOrder='asc'
        className={'row mx-0 p-3 bg-light fw-bold'}
        key='table-header'
        style={{ fontSize: '14px' }}
      />

      {loadingGetEventAndOrder && <LoadingComponent className='mt-5' />}

      {!loadingGetEventAndOrder && !listOrder?.length && (
        <EmptyStateMessage message='No Orders Found' />
      )}

      {!loadingGetEventAndOrder &&
        listOrder &&
        listOrder.length > 0 &&
        listOrder.map((order, index) => (
          <Row
            key={index}
            className={`${
              index % 2 !== 0 ? 'bg-light' : ''
            } list-event__item border-0 items-center p-3 m-0`}
          >
            <Col>
              <Link to='#' onClick={(e) => handleShowDetail(e, order)}>
                <p>{order.uName}</p>
              </Link>
              {order.comment && (
                <div className='d-flex align-items-center position-relative wrap-comment mt-3'>
                  <span className='me-2'>Note:</span>
                  <Link to='#' onClick={(e) => openMessageModal(e, order)}>
                    <i className='icon-new'></i>
                  </Link>
                </div>
              )}
            </Col>
            <Col>{order.uPhone}</Col>
            <Col>{order.eTitle}</Col>
            <Col>{moment(order.createdAt * 1000).format('DD/MM/YYYY')}</Col>
            <Col>
              {order.allProductPerOrder.map((item, i) => (
                <p key={i}>
                  {item.pName} (qty:{item.pQuantity})
                </p>
              ))}
            </Col>
            <Col className='text-center'>
              {listEventAndDelivery?.eventAndOrders?.find((event) => event.id === order.eid)
                ?.status === 0 ? (
                <span>Close Order</span>
              ) : (
                <span>Open Order</span>
              )}
            </Col>
            <Col className='text-center'>
              {order.buyerStatus === BUYER_UNPAID && (
                <span className='n-paid' style={{ paddingRight: 0 }}>
                  Not Paid
                </span>
              )}

              {order.buyerStatus === BUYER_PAID && (
                <Link
                  to='#'
                  className={`${order?.combinedPaymentRef ? 'paid-combined' : 'paid'} mx-auto`}
                  style={{
                    width: 'fit-content',
                    opacity: loadingGetListCombinedPayment ? 0.5 : 1,
                    pointerEvents: loadingGetListCombinedPayment ? 'none' : 'auto',
                  }}
                  data-toggle='modal'
                  data-target='#modal-paid'
                  onClick={(e) => openPaymentScreen(e, order)}
                  disabled={loadingGetListCombinedPayment}
                >
                  {/* <i className='icon-link me-1' style={{ width: 14, height: 14 }}></i> */}
                  <span>Paid</span>
                </Link>
              )}

              {order.buyerStatus === BUYER_CANCELED && <span className=''>Cancelled</span>}

              {order.buyerStatus === BUYER_COLLECTED && <span className=''>Collected</span>}

              {order.buyerStatus === BUYER_REFUNDED && <span className=''>Refunded</span>}
            </Col>
            <Col>
              <FormGroup>
                <Input
                  id='select'
                  name='select'
                  type='select'
                  className='form-control-payment'
                  value={order.status}
                  onChange={(e) =>
                    onChangeSelect(
                      e,
                      order.ref,
                      order.status,
                      order.buyerStatus,
                      order.combinedPaymentRef,
                      order.eid
                    )
                  }
                  style={
                    order.status === HOST_ORDER_UNPAID
                      ? { color: '#ff6903' }
                      : order.status === HOST_ORDER_VERIFIED
                      ? { color: '#51aaaa' }
                      : { color: '#7c797a' }
                  }
                >
                  <option value={HOST_ORDER_CANCELED}>Cancelled</option>
                  <option value={HOST_ORDER_UNPAID}>Unpaid</option>
                  <option value={HOST_ORDER_VERIFIED}>Verified</option>
                  <option value={HOST_ORDER_COLLECTED}>Collected</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
        ))}
      {Object.keys(dataModal).length !== 0 && (
        <ModalMessage data={dataModal} modalMessage={modalMessage} toggleMessage={toggleMessage} />
      )}
      {Object.keys(dataModal).length !== 0 && (
        <ModalPaymentScreen
          data={dataModal}
          modalPaymentScreen={modalPaymentScreen}
          togglePaymentScreen={togglePaymentScreen}
          handleInvalidPayment={handleInvalidPayment}
        />
      )}
      {Object.keys(dataModal).length !== 0 && (
        <ModalWarning
          data={dataModal}
          modalWarning={modalWarning}
          toggleWarning={toggleWarning}
          handleCancel={handleCancelOrder}
          isCanceling={isCanceling}
          isOrderEvent={true}
        />
      )}
      {Object.keys(dataModalDetailOrder).length !== 0 && (
        <ModalDetailOrder
          id={dataModalDetailOrder?.eid}
          order={dataModalDetailOrder}
          modalDetailOrder={modalDetailOrder}
          toggleDetailOrder={toggleDetailOrder}
          handleOpenWarning={handleOpenWarning}
          toggleWarning={toggleWarning}
        />
      )}
    </div>
  )
}

export default OrderList

import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaRegCalendarAlt, FaRegCreditCard, FaRegUser } from 'react-icons/fa'
import { MdCancel, MdEdit } from 'react-icons/md'
import * as yup from 'yup'
import CardFrame from './CardFrame'
import './styles.scss'

const schemeCardForm = yup.object().shape({
  cardName: yup.string().required('Card Name is required'),
  cardNumber: yup
    .string()
    .required('Card Number is required')
    .matches(/^(\d\s*){16,}$/, 'Card Number must be exactly 16 digits')
    .test('luhn-check', 'Invalid card number', () => true),
  expiryDate: yup
    .string()
    .required('Expiry Date is required')
    .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, 'Expiry Date must be in MM/YY format'),
  cvv: yup
    .string()
    .required('CVV is required')
    .matches(/^[0-9]{3,4}$/, 'CVV must be 3 or 4 digits'),
})

function CardInfo() {
  const [isEdit, setIsEdit] = useState(false)

  const toggleEdit = () => setIsEdit(!isEdit)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    reset,
    setValue,
  } = useForm({
    mode: 'all',
    defaultValues: {
      cardName: 'John Doe',
      cardNumber: '1234 5678 9876 5435',
      expiryDate: '12/25',
      cvv: '123',
    },
    resolver: yupResolver(schemeCardForm),
  })

  const cardInfo = watch()

  // eslint-disable-next-line no-console
  const onSubmit = (data) => {
    setValue('cardName', data.cardName)
    setValue('cardNumber', data.cardNumber)
    setValue('expiryDate', data.expiryDate)
    setValue('cvv', data.cvv)

    toggleEdit()
  }

  const onCancel = () => {
    reset()
    toggleEdit()
  }

  return (
    <div className='card-info'>
      <div className='info-card-payment row mb-5'>
        {/* <h5 className='fw-semibold text-black pl-0 title'>Card Info</h5> */}
        <div className='col-12 text-dark'>
          <CardFrame
            cardInfo={cardInfo}
            buttonEdit={() => (
              <button
                type='button'
                className='bg-transparent border-0 text-white fw-semibold rounded shadow-none mt-1 btn-edit d-flex align-items-center'
                onClick={toggleEdit}
              >
                {isEdit ? <MdCancel /> : <MdEdit />}
                <span className='ms-1'>{isEdit ? 'Cancel Edit' : 'Edit Card'}</span>
              </button>
            )}
          />
        </div>
      </div>

      {isEdit && (
        <FormEditCard
          onSubmit={onSubmit}
          cardInfo={cardInfo}
          isEdit={isEdit}
          onCancel={onCancel}
          {...{ register, handleSubmit, errors, isSubmitting }}
        />
      )}
    </div>
  )
}

function FormEditCard({
  onSubmit,
  register,
  handleSubmit,
  errors,
  isSubmitting,
  cardInfo,
  onCancel,
  isEdit,
}) {
  return (
    <div className='info-card-payment row mb-5'>
      <div className='col-12 text-dark'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='d-flex flex-column'>
            <div className='form-group'>
              <label htmlFor='card-name' className='fw-semibold'>
                Card Name
              </label>
              <div className='input-group'>
                <span className='input-group-text'>
                  <FaRegUser />
                </span>
                <input
                  type='text'
                  className='form-control'
                  id='card-name'
                  defaultValue={cardInfo.cardName}
                  {...register('cardName')}
                  placeholder='Enter card name'
                />
              </div>
              {errors.cardName && <div className='text-danger'>{errors.cardName.message}</div>}
            </div>
            <div className='form-group'>
              <label htmlFor='card-number' className='fw-semibold'>
                Card Number
              </label>
              <div className='input-group'>
                <span className='input-group-text'>
                  <FaRegCreditCard />
                </span>
                <input
                  type='text'
                  className='form-control'
                  id='card-number'
                  defaultValue={cardInfo.cardNumber}
                  {...register('cardNumber')}
                  placeholder='0000 0000 0000 0000'
                />
              </div>
              {errors.cardNumber && <div className='text-danger'>{errors.cardNumber.message}</div>}
            </div>
            <div className='row' style={{ marginTop: 10 }}>
              <div className='col-6'>
                <div className='form-group'>
                  <label htmlFor='expiry-date' className='fw-semibold'>
                    Expiry Date
                  </label>
                  <div className='input-group'>
                    <span className='input-group-text'>
                      <FaRegCalendarAlt />
                    </span>
                    <input
                      type='text'
                      className='form-control'
                      id='expiry-date'
                      defaultValue={cardInfo.expiryDate}
                      {...register('expiryDate')}
                      placeholder='MM/YY'
                    />
                  </div>
                  {errors.expiryDate && (
                    <div className='text-danger'>{errors.expiryDate.message}</div>
                  )}
                </div>
              </div>
              <div className='col-6'>
                <div className='form-group'>
                  <label htmlFor='cvv' className='fw-semibold'>
                    CVV
                  </label>
                  <div className='input-group'>
                    <span className='input-group-text'>
                      <FaRegCreditCard />
                    </span>
                    <input
                      type='text'
                      className='form-control'
                      id='cvv'
                      defaultValue={cardInfo.cvv}
                      {...register('cvv')}
                      placeholder='MM/YY'
                    />
                  </div>
                  {errors.cvv && <div className='text-danger'>{errors.cvv.message}</div>}
                </div>
              </div>
            </div>
            <button
              type='submit'
              className='btn btn-primary shadow-none fw-semibold rounded mt-3'
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Loading...' : 'Save'}
            </button>
            {isEdit && (
              <button
                type='button'
                className='btn btn-outline-secondary rounded shadow-none mt-3'
                onClick={onCancel}
              >
                Cancel
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default CardInfo

import shopApi from '_api/shop'
import { setListShopPaid } from '_utils/localData'

const GET_LIST_SHOP_PAID = 'GET_LIST_SHOP_PAID'
const CLEAR_LIST_SHOP_PAID = 'CLEAR_LIST_SHOP_PAID'

const initialState = {
  listShop: [],
}

export const getListShopPaid = () => async (dispatch) => {
  try {
    const { msgResp } = await shopApi.getListShopPaid()

    dispatch({
      type: GET_LIST_SHOP_PAID,
      payload: {
        listShop: msgResp,
      },
    })

    setListShopPaid(msgResp)
  } catch (error) {
    throw new Error(error)
  }
}

export const clearShopPaid = () => (dispatch) => {
  dispatch({
    type: CLEAR_LIST_SHOP_PAID,
    payload: {
      listShop: [],
    },
  })
  setListShopPaid([])
}

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_LIST_SHOP_PAID:
    case GET_LIST_SHOP_PAID:
      return {
        ...state,
        ...action.payload,
      }
    default: {
      return state
    }
  }
}

export default shopReducer

/* eslint-disable function-paren-newline */
import LoadingOverlay from '_components/LoadingOverlay'
import { getAllEventsOpening } from '_redux/modules/event'
import { EVENT_STATUS_OPEN } from '_utils/constant'
import { sortListEvents } from '_utils/function'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EventItem from '../../Event/components/EventItem'
import './style.scss'

const MyGroupBuy = () => {
  const dispatch = useDispatch()
  const [searchString, setSearchString] = useState('')
  const [loading, setLoading] = useState(false)

  const { allEvents } = useSelector((state) => state.event)

  const fetchData = async () => {
    try {
      setLoading(true)
      await dispatch(getAllEventsOpening)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onChangeSearch = (event) => {
    setSearchString(event.target.value)
  }

  if (loading) return <LoadingOverlay />

  return (
    <div className='row-top event-page my-3'>
      <div className='mb-3'>
        <div className='c-form c-form--search event-search'>
          <div className='form-group'>
            <i className='icon-search'></i>
            <input
              className='form-control'
              placeholder='Search group name'
              type='text'
              value={searchString}
              onChange={onChangeSearch}
            />
          </div>
        </div>
      </div>

      {!loading && !allEvents.length ? (
        <>
          <h6 className='mt-5 fw-bold text-center w-100'>No Events Found</h6>
        </>
      ) : (
        <div className='group-list group-list--event group-list--event02 pb-4'>
          {sortListEvents(allEvents)
            ?.filter((item) => item.closingTimestamp > moment().unix())
            ?.filter((item) => item.status === EVENT_STATUS_OPEN)
            .map((event, index) => (
              <EventItem event={event} key={index} />
            ))}
        </div>
      )}
    </div>
  )
}

export default MyGroupBuy

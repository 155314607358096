import Header from '_components/Header'
import useData from '_hooks/useData'
import Image from '_images/img_welcome.svg'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function Welcome() {
  useData()
  const history = useHistory()
  const { user } = useSelector((state) => state.user)
  const { allEvents } = useSelector((state) => state.event)
  const { listShop } = useSelector((state) => state.shop)

  useEffect(() => {
    if (user?.id) {
      const userHasGroup = user?.groups?.length > 0
      const hasEvents = allEvents.length > 0
      const hasShops = listShop.length > 0

      let path
      if (userHasGroup) {
        path = '/group/my-group-buy'
      } else if (hasEvents && hasShops) {
        path = '/'
      } else if (hasShops) {
        path = '/co-hoot'
      } else if (!userHasGroup) {
        path = '/group/create'
      }

      history.push(path)
    }
  }, [user?.id])

  return (
    <div className='welcome'>
      <Header />
      <section className='section-content section-content--top mains-screen' id='checkHeight'>
        <div className='container'>
          <div className='row-top welcome-page'>
            <h3 className='c-txt-25 text-center c-txt-org mt-3'>
              <strong>Welcome to Co-bee</strong>
            </h3>
            <div className='box-welcome'>
              <picture>
                <img src={Image} alt={'show-image'} />
              </picture>
              <div className='text-center'>
                <h4>Currently there is no deals hosted in your community</h4>
                <p>
                  In the meantime, <Link to='/user/profile'>leave a feedback</Link> on how we can
                  make Co-bee better
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Welcome

import eventApi from '_api/event'
import { LIMIT_TEXT } from '_utils/constant'
import { convertToCurrentGMT, shortDescription } from '_utils/function'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import ReactImageGallery from 'react-image-gallery'
import { useHistory } from 'react-router-dom'
import HeaderEventInfo from '../components/HeaderEventInfo'

export default function EventItem({ event }) {
  const history = useHistory()
  // const setOpenModalJoinGroup = useContext(ListAllEventContext)?.setOpenModalJoinGroup
  // const setGroup = useContext(ListAllEventContext)?.setGroup
  // const { user } = useSelector((state) => state.user)
  const [isShowMore, setIsShowMore] = useState(false)
  const [shareCode, setShareCode] = useState('')

  const listImages = event?.photoUrls?.map((item) => ({ original: item })) || []

  useEffect(() => {
    const getShareCode = async () => {
      const { msgCode, msgResp } = await eventApi.getShareEventCode(event.id)
      if (msgCode % 100 === 0) {
        setShareCode(msgResp.shareCode)
      }
    }

    if (event?.shareCode) {
      setShareCode(event.shareCode)
    } else {
      getShareCode()
    }
  }, [event])

  const showMore = () => {
    setIsShowMore(true)
  }

  // const fetchGroupById = async (groupId) => {
  //   try {
  //     return await groupApi.getById(groupId)
  //   } catch (error) {
  //     throw new Error(error.message || error.msgResp)
  //   }
  // }

  const directToEventDetail = async (selectedEvent) =>
    history.push(`/event/detail-shared?eid=${selectedEvent.id}&shareCode=${shareCode}`)
  // const groupId = selectedEvent?.groupId
  // const userInGroup = user?.groups?.find((item) => item.gid === groupId)

  // if (!user) {
  //   return history.push(`/event/detail?eid=${selectedEvent?.id}`)
  // }
  // if (!userInGroup) {
  //   setOpenModalJoinGroup(true)
  //   const { msgResp } = await fetchGroupById(groupId)
  //   setGroup(msgResp)
  //   return null
  // }

  return (
    <div className='group-list__item'>
      <HeaderEventInfo
        createdUserPhotoUrl={event?.createdUserPhotoUrl}
        createdUserName={event?.createdUserName}
        eClosingTime={convertToCurrentGMT(event?.closingTime)}
        eTotalOrderCount={event?.totalOrderCount}
        groupName={event?.groupName}
      />
      <div className='group-list__des'>
        {listImages?.length > 0 && (
          <ReactImageGallery
            items={listImages}
            lazyLoad
            autoPlay
            showPlayButton={false}
            showNav={false}
            showBullets={true}
            showThumbnails={false}
            onClick={() => directToEventDetail(event)}
          />
        )}

        <h4>
          <button
            className={'bg-transparent border-0 text-blue fw-semibold'}
            onClick={() => directToEventDetail(event)}
          >
            {event?.title}
          </button>
        </h4>
        <p className='paragraph-pre-wrap'>
          <button className={'bg-transparent border-0'} onClick={() => directToEventDetail(event)}>
            {isShowMore
              ? event?.description
              : shortDescription({ description: event?.description })}
          </button>
          {!isShowMore &&
          event?.description &&
          event?.description.split(' ').length > LIMIT_TEXT ? (
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer', display: 'inline' }}
              onClick={showMore}
            >
              More
            </span>
          ) : null}
        </p>
      </div>
    </div>
  )
}

EventItem.propTypes = {
  event: PropTypes.shape({
    createdUserId: PropTypes.string,
    groupId: PropTypes.string,
  }),
}

// eslint-disable-next-line max-len
import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'

import { DOMAIN } from '_utils/constant'

import Logo01 from '_images/copyright.svg'
import Logo02 from '_images/logo_02.svg'
import { convertToCurrentGMT, isDisableEvent, uniqueEvents } from '_utils/function'
import { useSelector } from 'react-redux'

import './style.scss'

const Header = ({ location }) => {
  const { pathname } = location
  const [totalEvents, setTotalEvents] = useState(0)
  const { allEvents, eventsPublic } = useSelector((state) => state.event)
  const { user } = useSelector((state) => state.user)
  const { myOrders } = useSelector((state) => state.order)

  const [totalMyPurchases, setTotalMyPurchases] = useState(0)

  const myGroups = user?.groups || []

  const currDomainFeature = user?.domainFeatures?.find((item) => item.domainName === DOMAIN) || {}
  // for user can see all events from group private and public
  // allEvents: list of all events from group private and public and from shop
  // eventsPublic: list of all events from group public
  // if events from group public, group private is the same
  // then it will be filtered to unique events
  const combinedListEvents = uniqueEvents(allEvents, eventsPublic)

  // Hàm kiểm tra xem user có phải là thành viên của group hay không
  const isUserMemberOfGroup = (groupId) => myGroups.some((g) => g.gid === groupId)

  // Lọc sự kiện
  const filteredEvents = combinedListEvents.filter((event) => {
    if (isUserMemberOfGroup(event.groupId)) {
      return true
    }
    return false
  })

  const group =
    user?.groups?.length && user?.groups?.find((g) => currDomainFeature.groupId === g.gid)

  useEffect(() => {
    if (filteredEvents?.length) {
      setTotalEvents(filteredEvents.length)
    }
  }, [filteredEvents?.length])

  useEffect(() => {
    const listOrderUnClosed = myOrders.filter((item) => {
      const isDisable = isDisableEvent(
        item?.status,
        item?.buyerStatus,
        convertToCurrentGMT(item?.ePickupTime),
        item?.ePickupDuration
      )
      if (!isDisable) {
        return item
      }
      return null
    })
    setTotalMyPurchases(listOrderUnClosed.length)
  }, [myOrders])

  return (
    <>
      <div className='header align-self-start'>
        <div className='container'>
          {user?.groups?.length === 1 && !group ? (
            <div className='main-logo text-left'>
              <h4 className='ttl-grp'>{user?.groups?.[0]?.name}</h4>
              <p className='ttl-company'>
                <img src={Logo01} alt='' />
              </p>
            </div>
          ) : group ? (
            <div className='main-logo'>
              <Link to='/'>
                <img src={Logo02} alt={'main-logo'} />
                <span>{group?.name}</span>
              </Link>
            </div>
          ) : (
            <div className='main-logo '>
              <Link to='/'>
                <img src={Logo02} alt={'main-logo'} />
                <span>Good things must share!</span>
              </Link>
            </div>
          )}

          <Link to='/user/profile' className='avatar-top'>
            <div className='avatar-top__email'>
              <span id='totalNotififications'></span> <i className='icon-email'></i>
            </div>
            <div className='avatar-top__img'>
              <img
                id='userAvatar'
                src={user?.photoUrl || process.env.REACT_APP_AVATAR_URL + user?.name}
                alt=''
              />
            </div>
          </Link>
        </div>
        <div className='nav-menu'>
          <div className='container'>
            <div className='row'>
              <div className='col-4'>
                <Link to='/' className={pathname[1] === 'all-page' ? 'active' : ''}>
                  All {!!allEvents?.length && <span id='totalEvents'>({totalEvents})</span>}
                </Link>
              </div>
              <div className='col-4'>
                <Link
                  to='/event/list-my-purchases'
                  className={pathname[1] === 'list-my-purchases' ? 'active' : ''}
                >
                  My Purchase
                  {!!totalMyPurchases && <span id='totalMyPurchases'>({totalMyPurchases})</span>}
                </Link>
              </div>
              <div className='col-4'>
                <Link
                  to='/group/list-my-groups'
                  className={pathname[1] === 'list-my-groups' ? 'active' : ''}
                >
                  My Group
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Header)

import Footer from '_components/Footer'
import Header from '_components/Header'
import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'

import { DeviceUserContext } from '_context/DeviceUserContext'
import { clearGroupDetailById } from '_redux/modules/group'
import { fetchUserInfo } from '_redux/modules/user'
import { getModalInfoCreateGroup, removeGroupIdSelected } from '_utils/localData'
import Joyride, { STATUS } from 'react-joyride'
import { useDispatch, useSelector } from 'react-redux'
import SelectGroupContext from '../context/SelectGroupContext'
import AllPage from './AllPage'
import EventPage from './Event'
import GroupPage from './Group'
import ShopList from './Shop/ShopList'
import UserPage from './User'

const MainPage = () => {
  const dispatch = useDispatch()

  const { checkDeviceUser } = useContext(DeviceUserContext)
  const { firstLogin, user } = useSelector((state) => state.user)

  const [state, setState] = useState({
    run: false,
    steps: [
      {
        target: '.step-1',
        content: (
          <div className='border border-success p-3'>
            <u className='mb-3 d-block fw-bold'>Features Upgrade</u>
            <p>This tab will show all GroupBuy & deals available in Co-bee</p>
          </div>
        ),
        placement: 'bottom',
        disableBeacon: true,
        locale: {
          close: 'Ok',
        },
      },
      {
        target: '.step-2',
        content: (
          <div className='border border-success p-3'>
            <u className='mb-3 d-block fw-bold'>Features Upgrade</u>
            <p>This tab will show your existing GroupBuy that you have joined.</p>
          </div>
        ),
        placement: 'bottom',
        disableBeacon: true,
        locale: {
          close: 'Ok',
        },
      },
      {
        target: '.step-3',
        content: (
          <div className='border border-success p-3'>
            <u className='mb-3 d-block fw-bold'>Features Upgrade</u>
            <p>This tab will show our partners that is helping to keep Co-bee Alive!</p>
          </div>
        ),
        placement: 'bottom',
        disableBeacon: true,
        locale: {
          close: 'Ok',
        },
      },
      {
        target: '.step-4',
        content: (
          <div className='border border-success p-3'>
            <u className='mb-3 d-block fw-bold'>Features Upgrade</u>
            <p>This tab will show our partners that is helping to keep Co-bee Alive!</p>
          </div>
        ),
        placement: 'top',
        disableBeacon: true,
        locale: {
          close: 'Ok',
        },
      },
    ],
  })

  const modalInfoCreateGroup = getModalInfoCreateGroup()

  const fetchAllData = async () => {
    await Promise.all([dispatch(fetchUserInfo())])
  }

  useEffect(() => {
    fetchAllData()
  }, [])

  useEffect(() => {
    if (checkDeviceUser === 'PHONE') {
      removeGroupIdSelected()
      dispatch(clearGroupDetailById())
    }
  }, [checkDeviceUser])

  useEffect(() => {
    const conditionalOne =
      !user?.groups?.length && modalInfoCreateGroup !== null && !modalInfoCreateGroup?.isOpen
    const conditionalTwo =
      user?.groups?.length && (modalInfoCreateGroup === null || !modalInfoCreateGroup?.isOpen)

    if (firstLogin && (conditionalOne || conditionalTwo)) {
      setState({
        ...state,
        run: true,
      })
    }
  }, [JSON.stringify(modalInfoCreateGroup)])

  const handleJoyrideCallback = (data) => {
    const { status } = data
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]

    if (finishedStatuses.includes(status)) {
      setState({ ...state, run: false })
    }
  }

  return (
    <React.Fragment>
      <SelectGroupContext>
        <Joyride
          steps={state.steps}
          styles={{
            options: {
              arrowColor: 'white',
              backgroundColor: 'white',
              border: '1px solid green',
              primaryColor: '#73d042',
              width: 300,
              zIndex: 1000,
              position: 'fixed',
            },
            buttonNext: {
              position: 'absolute',
              bottom: '1.8rem',
              left: '35%',
              width: '100px',
            },
          }}
          run={state.run}
          callback={handleJoyrideCallback}
          hideCloseButton
          hideBackButton
          scrollToFirstStep
          showProgress
        />
        <Header />
        <section className='section-content section-content--top mains-screen' id='checkHeight'>
          <div className='container'>
            <Switch>
              <Route path='/co-hoot' component={ShopList} />
              <Route path='/user' component={UserPage} />
              <Route path='/group' component={GroupPage} />
              <Route path='/event' component={EventPage} />
              <Route path='/' exact component={AllPage} />
            </Switch>
          </div>
        </section>
        <Footer />
      </SelectGroupContext>
    </React.Fragment>
  )
}

export default MainPage

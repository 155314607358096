/* eslint-disable no-unused-vars */
import { GROUP_APPROVED } from '_utils/constant'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

function TopMembers() {
  const { addToast } = useToasts()

  const { summaryData, errorGetSummary } = useSelector((state) => state.dashBoard)

  if (errorGetSummary) {
    return addToast(errorGetSummary, { appearance: 'error', autoDismiss: true })
  }

  const topNewMembers = useMemo(() => summaryData?.summary?.newMemberDetail, [summaryData])
  // const data = [
  //   {
  //     approval: 2,
  //     isAdmin: 0,
  //     banned: 0,
  //     answer: null,
  //     orderCount: 0,
  //     subNotifs: [1],
  //     question: null,
  //     gid: 'DjBUX5KEpFuzNvS6oc8M',
  //     uid: '8psoDpiTfCOXFncTM1vV',
  //     request: 3,
  //     passcode: '',
  //     uName: 'Trung Tran',
  //     createdAt: 1659709644,
  //     isHost: 0,
  //     lastModifiedAt: 0,
  //   },
  //   {
  //     approval: 2,
  //     isAdmin: 0,
  //     banned: 0,
  //     answer: null,
  //     orderCount: 0,
  //     subNotifs: [1],
  //     question: null,
  //     gid: 'DjBUX5KEpFuzNvS6oc8M',
  //     uid: '8psoDpiTfCOXFncTM1vV',
  //     request: 3,
  //     passcode: '',
  //     uName: 'Trung Tran',
  //     createdAt: 1659709644,
  //     isHost: 0,
  //     lastModifiedAt: 0,
  //   },
  //   {
  //     approval: 2,
  //     isAdmin: 0,
  //     banned: 0,
  //     answer: null,
  //     orderCount: 0,
  //     subNotifs: [1],
  //     question: null,
  //     gid: 'DjBUX5KEpFuzNvS6oc8M',
  //     uid: '8psoDpiTfCOXFncTM1vV',
  //     request: 3,
  //     passcode: '',
  //     uName: 'Trung Tran',
  //     createdAt: 1659709644,
  //     isHost: 0,
  //     lastModifiedAt: 0,
  //   },
  //   {
  //     approval: 2,
  //     isAdmin: 0,
  //     banned: 0,
  //     answer: null,
  //     orderCount: 0,
  //     subNotifs: [1],
  //     question: null,
  //     gid: 'DjBUX5KEpFuzNvS6oc8M',
  //     uid: '8psoDpiTfCOXFncTM1vV',
  //     request: 3,
  //     passcode: '',
  //     uName: 'Trung Tran',
  //     createdAt: 1659709644,
  //     isHost: 0,
  //     lastModifiedAt: 0,
  //   },
  //   {
  //     approval: 2,
  //     isAdmin: 0,
  //     banned: 0,
  //     answer: null,
  //     orderCount: 0,
  //     subNotifs: [1],
  //     question: null,
  //     gid: 'DjBUX5KEpFuzNvS6oc8M',
  //     uid: '8psoDpiTfCOXFncTM1vV',
  //     request: 3,
  //     passcode: '',
  //     uName: 'Trung Tran',
  //     createdAt: 1659709644,
  //     isHost: 0,
  //     lastModifiedAt: 0,
  //   },
  //   {
  //     approval: 2,
  //     isAdmin: 0,
  //     banned: 0,
  //     answer: null,
  //     orderCount: 0,
  //     subNotifs: [1],
  //     question: null,
  //     gid: 'DjBUX5KEpFuzNvS6oc8M',
  //     uid: '8psoDpiTfCOXFncTM1vV',
  //     request: 3,
  //     passcode: '',
  //     uName: 'Trung Tran',
  //     createdAt: 1659709644,
  //     isHost: 0,
  //     lastModifiedAt: 0,
  //   },
  //   {
  //     approval: 2,
  //     isAdmin: 0,
  //     banned: 0,
  //     answer: null,
  //     orderCount: 0,
  //     subNotifs: [1],
  //     question: null,
  //     gid: 'DjBUX5KEpFuzNvS6oc8M',
  //     uid: '8psoDpiTfCOXFncTM1vV',
  //     request: 3,
  //     passcode: '',
  //     uName: 'Trung Tran',
  //     createdAt: 1659709644,
  //     isHost: 0,
  //     lastModifiedAt: 0,
  //   },
  //   {
  //     approval: 2,
  //     isAdmin: 0,
  //     banned: 0,
  //     answer: null,
  //     orderCount: 0,
  //     subNotifs: [1],
  //     question: null,
  //     gid: 'DjBUX5KEpFuzNvS6oc8M',
  //     uid: '8psoDpiTfCOXFncTM1vV',
  //     request: 3,
  //     passcode: '',
  //     uName: 'Trung Tran',
  //     createdAt: 1659709644,
  //     isHost: 0,
  //     lastModifiedAt: 0,
  //   },
  //   {
  //     approval: 2,
  //     isAdmin: 0,
  //     banned: 0,
  //     answer: null,
  //     orderCount: 0,
  //     subNotifs: [1],
  //     question: null,
  //     gid: 'DjBUX5KEpFuzNvS6oc8M',
  //     uid: '8psoDpiTfCOXFncTM1vV',
  //     request: 3,
  //     passcode: '',
  //     uName: 'Trung Tran',
  //     createdAt: 1659709644,
  //     isHost: 0,
  //     lastModifiedAt: 0,
  //   },
  //   {
  //     approval: 2,
  //     isAdmin: 0,
  //     banned: 0,
  //     answer: null,
  //     orderCount: 0,
  //     subNotifs: [1],
  //     question: null,
  //     gid: 'DjBUX5KEpFuzNvS6oc8M',
  //     uid: '8psoDpiTfCOXFncTM1vV',
  //     request: 3,
  //     passcode: '',
  //     uName: 'Trung Tran',
  //     createdAt: 1659709644,
  //     isHost: 0,
  //     lastModifiedAt: 0,
  //   },
  // ]

  const page = {
    currentPage: 1,
    ordersPerPage: 10,
  }

  const { currentPage, ordersPerPage } = page

  // Logic for displaying orders
  const indexOfLastOrder = currentPage * ordersPerPage
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage

  // Logic for displaying page numbers
  // const pageNumbers = []
  // for (let i = 1; i <= Math.ceil(topNewMembers?.length / ordersPerPage); i++) {
  //   pageNumbers.push(i)
  // }

  // const handlePrev = () => {
  //   if (currentPage > 1) {
  //     setPage({
  //       ...page,
  //       currentPage: currentPage - 1,
  //     })
  //   }
  // }

  // const handleNext = () => {
  //   if (currentPage < pageNumbers.length) {
  //     setPage({
  //       ...page,
  //       currentPage: currentPage + 1,
  //     })
  //   }
  // }

  const currentData = topNewMembers?.slice(indexOfFirstOrder, indexOfLastOrder)

  return (
    <div className='top-members text-center'>
      <p style={{ color: '#414141' }}>Top 10 Members</p>
      <ul className='top-members_list mb-auto'>
        {currentData?.map((member, index) => (
          <li key={index} className='justify-content-start'>
            {index + 1}.<span className='user-name float-start ms-2'>{member.uName}</span>
            {member.approval === GROUP_APPROVED ? (
              <span className='user-status ms-auto' style={{ color: '#6CCBCB' }}>
                Approved
              </span>
            ) : (
              <span className='user-status ms-auto' style={{ color: '#FF6805' }}>
                Pending
              </span>
            )}
          </li>
        ))}
      </ul>
      {/* <div className='my-3 d-flex align-items-center' style={{ fontSize: 16, margin: 0 }}>
        <Pagination onHandlePrev={handlePrev} onHandleNext={handleNext} currentData={currentData} />
      </div> */}
    </div>
  )
}

export default TopMembers

import { DEFAULT_KEY, generateCacheTTL } from 'redux-cache'
import orderApi from '_api/order'
import eventApi from '_api/event'
import serviceApi from '_api/service'
import { handleDataOrder } from '_utils/function'
import { getDomain, getUserInfo, setDomain } from '_utils/localData'
import { DOMAIN, STATUS_PAID } from '_utils/constant'

const GET_MY_ORDERS = 'GET_MY_ORDERS'
const DELETE_ORDER = 'DELETE_ORDER'
const CANCEL_ORDER = 'CANCEL_ORDER'
const SET_ERROR_ORDER = 'SET_ERROR_ORDER'
const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL'
const ADD_NEW_ORDER = 'ADD_NEW_ORDER'
const UPDATE_ORDER = 'UPDATE_ORDER'
const CLEAR_ORDER_DETAIL = 'CLEAR_ORDER_DETAIL'
const UPDATE_ORDER_DETAIL = 'UPDATE_ORDER_DETAIL'
const UPDATE_MY_ORDER = 'UPDATE_MY_ORDER'
const CLEAR_MY_ORDER = 'CLEAR_MY_ORDER'

const initialState = {
  [DEFAULT_KEY]: null,
  error: null,
  myOrders: [],
  orderDetail: [],
  errorOrderDetail: null,
  listHostGroup: [],
  listHostId: [],
  listHostDetail: []
}

export const getMyOrders = async (dispatch, getState) => {
  try {
    const { order } = getState()
    const { myOrders = [] } = order
    if (myOrders && myOrders.length > 0) {
      return
    }
    const user = getUserInfo()
    // if (!user || !user?.id) {
    //   const userRes = await userApi.getUser()
    //   user = userRes.msgResp
    // }
    if (user && user?.id) {
      let domainLocal = getDomain(DOMAIN)
      const parser = document.createElement('a')
      parser.href = process.env.REACT_APP_APP_URL

      if (!domainLocal && DOMAIN !== parser.hostname) {
        const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
        const { groupId } = msgResp

        domainLocal = groupId
        setDomain(DOMAIN, groupId)
      }

      const response = await orderApi.getListOrdersByBuyer(domainLocal)
      const { msgResp } = response
      if (msgResp && msgResp.length) {
        const uniqueListHostDetail = []
        const listIdHost = msgResp.map((item) => item.eCreatedUserId)
        const uniqueListIdHost = new Set([...listIdHost])
        const listHostGroup = msgResp
          .map(({ eGroupId, eCreatedUserId }) => ({ eGroupId, eCreatedUserId }))
        const uniqueListHostGroup = []

        listHostGroup.map((item) => (uniqueListHostGroup
          .filter((hostGroup) => hostGroup.eGroupId === item.eGroupId
            && hostGroup.eCreatedUserId === item.eCreatedUserId)
          .length > 0 ? null : uniqueListHostGroup.push(item)))

        uniqueListHostGroup.forEach((element) => {
          const { eCreatedUserName, eCreatedUserPhotoUrl, eGroupName, eCreatedUserId, eGroupId } =
            msgResp.find((item) => item.eCreatedUserId === element.eCreatedUserId
              && item.eGroupId === element.eGroupId)
          uniqueListHostDetail.push({
            eCreatedUserName, eCreatedUserPhotoUrl, eGroupName, eCreatedUserId, eGroupId
          })
        })

        dispatch({
          type: GET_MY_ORDERS,
          payload: {
            listHostGroup: Array.from(uniqueListHostGroup),
            listHostId: Array.from(uniqueListIdHost),
            listHostDetail: uniqueListHostDetail
          }
        })

        let newOrders = []
        msgResp.forEach(async (r) => {
          const posOrderDuplicate = newOrders.findIndex((n) => n.ref === r.ref)
          if (posOrderDuplicate !== -1) {
            newOrders[posOrderDuplicate].pQuantity += r.pQuantity
          } else {
            newOrders = [...newOrders, { ...r }]
          }
          dispatch({
            type: GET_MY_ORDERS,
            payload: { myOrders: newOrders },
          })
        })
      }
    } else {
      return []
    }
  } catch (error) {
    dispatch({
      type: SET_ERROR_ORDER,
      payload: error,
    })
  }
}

export const clearMyOrder = () => (dispatch) => {
  dispatch({
    type: CLEAR_MY_ORDER,
    payload: { myOrders: [] }
  })
}

export const deleteOrder = (eid) => async (dispatch, getState) => {
  try {
    const { order } = getState()
    const { myOrders } = order
    const newMyOrders = myOrders.filter((item) => item.eid !== eid)
    dispatch({
      type: DELETE_ORDER,
      payload: { myOrders: newMyOrders },
    })
  } catch (error) { }
}
export const cancelOrder = (ref) => async (dispatch, getState) => {
  try {
    const { order } = getState()
    const { myOrders, orderDetail } = order
    const newMyOrders = myOrders
    for (let index = 0; index < newMyOrders.length; index++) {
      if (newMyOrders[index].ref === ref) {
        newMyOrders[index].buyerStatus = -1
      }
    }
    const newOrderDetail = orderDetail.filter((item) => item.ref !== ref)
    dispatch({
      type: CANCEL_ORDER,
      payload: { myOrders: newMyOrders, orderDetail: newOrderDetail },
    })
  } catch (error) { }
}
export const updateMyOrder =
  (ref, _buyerStatus, combinedPaymentRef) => async (dispatch, getState) => {
    try {
      const { order } = getState()
      const { myOrders } = order
      const newMyOrders = myOrders
      for (let index = 0; index < newMyOrders.length; index++) {
        if (newMyOrders[index].ref === ref) {
          newMyOrders[index].buyerStatus = _buyerStatus
          newMyOrders[index].combinedPaymentRef = combinedPaymentRef
        }
      }
      dispatch({
        type: UPDATE_MY_ORDER,
        payload: { myOrders: newMyOrders },
      })
    } catch (error) { }
  }

export const updateMyOrderWithDelivery = (ref, _buyerStatus) => async (dispatch, getState) => {
  try {
    const { order } = getState()
    const { myOrders } = order
    const newMyOrders = myOrders
    for (let index = 0; index < newMyOrders.length; index++) {
      if (newMyOrders[index].deliveryBooking && newMyOrders[index].deliveryBooking.ref === ref) {
        newMyOrders[index].deliveryBooking.bookerStatus = _buyerStatus
      }
    }
    dispatch({
      type: UPDATE_MY_ORDER,
      payload: { myOrders: newMyOrders },
    })
  } catch (error) { }
}

export const clearDataPurchaseDetailByRef = (ref) => async (dispatch, getState) => {
  try {
    const { order } = getState()
    const { orderDetail } = order
    const newOrderDetail = orderDetail.filter((item) => item.ref !== ref)
    dispatch({
      type: CLEAR_ORDER_DETAIL,
      payload: { orderDetail: newOrderDetail },
    })
  } catch (error) { }
}
export const clearDataPurchaseDetailByEid = (eid) => async (dispatch, getState) => {
  try {
    const { order } = getState()
    const { orderDetail } = order
    const newOrderDetail = orderDetail.filter((item) => item.eid !== eid)
    dispatch({
      type: CLEAR_ORDER_DETAIL,
      payload: { orderDetail: newOrderDetail },
    })
  } catch (error) { }
}

export const clearDataPurchaseDetail = () => (dispatch) => {
  dispatch({
    type: CLEAR_ORDER_DETAIL,
    payload: { orderDetail: [] }
  })
}

export const updateDataPurchaseDetail =
  (ref, buyerStatus, combinedPaymentRef) => async (dispatch, getState) => {
    try {
      const { order } = getState()
      const { orderDetail } = order
      const newOrderDetail = orderDetail
      const indexOrder = newOrderDetail.findIndex((item) => item.ref === ref)
      if (indexOrder !== -1) {
        newOrderDetail[indexOrder].buyerStatus = buyerStatus
        newOrderDetail[indexOrder].combinedPaymentRef = combinedPaymentRef
      }
      dispatch({
        type: UPDATE_ORDER_DETAIL,
        payload: { orderDetail: newOrderDetail },
      })
    } catch (error) { }
  }
export const updateCommentPurchaseDetail = (ref, comment) => async (dispatch, getState) => {
  try {
    const { order } = getState()
    const { orderDetail } = order
    const newOrderDetail = orderDetail
    const indexOrder = newOrderDetail.findIndex((item) => item.ref === ref)
    if (indexOrder !== -1) {
      newOrderDetail[indexOrder].comment = comment
    }
    dispatch({
      type: UPDATE_ORDER_DETAIL,
      payload: { orderDetail: newOrderDetail },
    })
  } catch (error) { }
}
export const getPurchaseDetail = (ref) => async (dispatch, getState) => {
  try {
    const { order } = getState()
    const { orderDetail } = order
    if (orderDetail.findIndex((item) => item.ref === ref) !== -1) return
    let newOrderDetail = {}
    let event = {}
    let _numBuy = 0
    let placedBy = []
    let listOrderByEventId = {}
    let productOrder = []
    // let eventCreatedUser = {}
    const response = await orderApi.getListOrderByRef(ref)
    const { msgResp } = response
    if (msgResp && msgResp.length > 0) {
      /* get event */
      event = await eventApi.getEvent(msgResp[0].eid)
      /* get User Create Event */
      // eventCreatedUser = await userApi.getUserById(msgResp[0].eCreatedUserId)
      /* get List Order by Event Id */
      listOrderByEventId = await orderApi.getListOrdersByEventId(msgResp[0].eid)

      const { listOrder, numBuy, placeBy } = handleDataOrder(
        listOrderByEventId.msgResp,
        event.msgResp.adminCost,
        event.msgResp.discount,
        event.msgResp.deliveryCost,
        event.msgResp.productIdList,
      )
      placedBy = placeBy
      _numBuy = numBuy
      productOrder = listOrder.find((item) => item.ref === ref)
      newOrderDetail = {
        ref,
        eid: msgResp[0].eid,
        event: event.msgResp,
        productOrder,
        // eventCreatedUser: eventCreatedUser.msgResp,
        numBuy: _numBuy,
        placedBy,
        orderNo: msgResp[0].eOrderNo,
        status: msgResp[0].status,
        buyerStatus: msgResp[0].buyerStatus,
        comment: msgResp[0].comment,
        combinedPaymentRef: msgResp[0].combinedPaymentRef || ''
      }
    }
    dispatch({
      type: GET_ORDER_DETAIL,
      payload: {
        orderDetail: [...orderDetail, newOrderDetail],
        errorOrderDetail: null
      },
    })
  } catch (error) {
    dispatch({
      type: GET_ORDER_DETAIL,
      payload: {
        errorOrderDetail: error
      },
    })
  }
}

export const getListPurchaseDetail = (listRef) => async (dispatch, getState) => {
  const { order } = getState()
  const { orderDetail = [] } = order
  const listPurchaseDetail = []
  // eslint-disable-next-line array-callback-return
  for (const _ref of listRef) {
    const exitOrder = orderDetail.findIndex((item) => item.ref === _ref)
    const exitListPD = listPurchaseDetail.findIndex((item) => item.ref === _ref)
    if (exitOrder === -1 && exitListPD === -1) {
      const { msgResp: orderByRef } = await orderApi.getListOrderByRef(_ref)
      if (orderByRef && orderByRef.length > 0) {
        /* get event */
        const { eid } = orderByRef[0]
        const { msgResp: event } = await eventApi.getEvent(eid)
        const { adminCost, discount, deliveryCost, productIdList } = event
        /* get List Order by Event Id */
        const { msgResp: listOrderByEventId } = await orderApi.getListOrdersByEventId(eid)

        const { listOrder } = handleDataOrder(
          listOrderByEventId,
          adminCost,
          discount,
          deliveryCost,
          productIdList
        )
        const productOrder = listOrder.find((item) => item.ref === _ref)
        const newOrderDetail = {
          ref: _ref,
          eid,
          event,
          productOrder,
          orderNo: orderByRef[0].eOrderNo,
          status: orderByRef[0].status,
          buyerStatus: orderByRef[0].buyerStatus,
          comment: orderByRef[0].comment,
        }
        listPurchaseDetail.push(newOrderDetail)
      }
    }
    if (listPurchaseDetail.length) {
      dispatch({
        type: GET_ORDER_DETAIL,
        payload: {
          orderDetail: [...orderDetail, ...listPurchaseDetail],
          errorOrderDetail: null
        },
      })
    }
  }
}

export const storeNewOrder = (newOrder) => (dispatch, getState) => {
  const { order } = getState()
  const { myOrders } = order

  try {
    dispatch({
      type: ADD_NEW_ORDER,
      payload: [newOrder, ...myOrders],
    })
  } catch (error) {
    console.error('error', error)
  }
}

export const updateReduxOrder = (newOrder) => (dispatch, getState) => {
  const { order } = getState()
  let { myOrders } = order
  myOrders = myOrders?.map((item) => {
    if (item.ref === newOrder.ref) {
      return { ...item, ...newOrder }
    }
    return item
  })

  dispatch({
    type: UPDATE_ORDER,
    payload: [...myOrders],
  })
}

const order = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_ORDERS: {
      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(),
        ...action.payload
        // myOrders: action.payload,
      }
    }
    case SET_ERROR_ORDER: {
      return {
        ...state,
        error: action.payload,
      }
    }
    case DELETE_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CANCEL_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_ORDER_DETAIL: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_ORDER_DETAIL: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_ORDER_DETAIL: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case ADD_NEW_ORDER: {
      return {
        ...state,
        myOrders: action.payload,
      }
    }
    case UPDATE_ORDER: {
      return {
        ...state,
        myOrders: action.payload,
      }
    }
    case UPDATE_MY_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_MY_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default order
